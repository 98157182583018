import React, {ReactElement, useEffect, useState} from 'react';
import {sanitizeUppercase} from '../lib/utils';
import {supportedLocales} from '../config/app.json';
import labelsJson from '../config/labels.json';

const defaultLocale = supportedLocales[0];

interface PageMeta {
  title: string;
  description?: string;
}

interface AppContextProps {
  locale: string;
  setLocale: (locale: string) => void;
  getLabel: (labelPath: string[], uppercase?: boolean) => string;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  pageMeta: PageMeta;
  setPageMeta: (meta: PageMeta) => void;
}

export const AppContext = React.createContext<AppContextProps>({
  locale: '',
  setLocale: () => null,
  getLabel: () => '',
  isLoading: false,
  setIsLoading: () => null,
  pageMeta: {title: ''},
  setPageMeta: (meta: PageMeta) => null,
});

interface AppContextProviderProps {
  children: ReactElement;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({children}) => {
  const [locale, setLocale] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageMeta, setPageMeta] = useState({title: ''});

  useEffect(() => {
    const localeFromUrl = window.location.pathname.split('/').filter(Boolean)[0];
    setLocale(isValidLocale(localeFromUrl) ? localeFromUrl : defaultLocale);
  }, [locale]);

  const isValidLocale = (locale: string) => supportedLocales.some((item) => item === locale);

  const setLocalizedUrl = (locale: string) => {
    // router.push(getLocalizedUrl(locale));
  };

  const getLocalizedUrl = (locale?: string) => {
    const {pathname} = window.location;
    const pathParts = pathname.split('/').filter(Boolean);
    const langFromPath = pathParts[0];
    let cleanPath = pathname;
    let nextLocale = locale || langFromPath;

    if (!isValidLocale(nextLocale)) nextLocale = defaultLocale;

    if (isValidLocale(langFromPath)) {
      cleanPath = pathParts.slice(1).join('/');
    } else {
      cleanPath = pathParts.join('/');
    }

    const localePrefix = nextLocale === defaultLocale ? '' : `/${nextLocale}`;

    return `${localePrefix}/${cleanPath}`;
  };

  const getLabel = (labelPath: string[], uppercase: boolean = false): string => {
    if (!labelsJson || !locale) return '';
    const labels: any = labelsJson;
    const labelSet = labels[locale];
    let label = '';
    switch (labelPath.length) {
      case 1:
        label = labelSet[labelPath[0]];
        break;
      case 2:
        label = labelSet[labelPath[0]] ? labelSet[labelPath[0]][labelPath[1]] : null;
        break;
      case 3:
        label =
          labelSet[labelPath[0]] && labelSet[labelPath[0]][labelPath[1]]
            ? labelSet[labelPath[0]][labelPath[1]][labelPath[2]]
            : null;
        break;
    }
    if (uppercase) label = sanitizeUppercase(label);
    return label || `# ${labelPath} #`;
  };

  return (
    <AppContext.Provider
      value={{
        locale,
        setLocale: setLocalizedUrl,
        getLabel,
        isLoading,
        setIsLoading,
        pageMeta,
        setPageMeta,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContext.displayName = 'AppContext';
