import React, {useContext} from 'react';
import Helmet from 'react-helmet';
import {AppContext} from '../../context/AppContext';
import {appName} from '../../config/app.json';

interface Props {
  image?: string;
  type?: string;
}

export default function Head(props: Props) {
  const {image, type} = props;
  const {
    locale,
    pageMeta: {title, description},
  } = useContext(AppContext);

  const titleTemplate = title ? `${title} | ${appName}` : appName;
  const defaultImage = window.location.origin + '/share.png';
  const defaultType = 'website';

  return (
    <Helmet>
      <html lang={locale} />
      <title>{titleTemplate}</title>
      <meta name="description" content={description} />

      <meta itemProp="name" content={titleTemplate} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image || defaultImage} />

      <meta property="og:title" content={titleTemplate} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:type" content={type || defaultType} />
      <meta property="og:url" content={window.location.href} />
      <meta property="site_name" content={appName} />
    </Helmet>
  );
}
