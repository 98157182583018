import React, {ReactElement} from 'react';
import {Waypoint} from 'react-waypoint';
import anime from 'animejs';

interface Props {
  animation?: 'slide' | 'scale';
  duration?: number;
  reverse?: boolean;
  offsetY?: number;
  children: object;
}

export default function AnimateIn(props: Props): ReactElement {
  const {animation, duration, reverse, offsetY, children} = props;

  const y = offsetY || 60;
  const d = duration || 1000;
  const initialY = animation === 'slide' ? (reverse ? -y : y) : 0;

  const createRandomString = () => [...Array(10)].map((i) => (~~(Math.random() * 36)).toString(36)).join('');
  const elemKey = createRandomString();

  React.useEffect(() => {
    const elems = document.querySelectorAll(`.waypoint-${elemKey} > *`);
    anime.set(elems, {
      translateY: initialY,
      scale: animation === 'scale' ? 0.9 : 1,
      opacity: 0,
    });
  }, []);

  const animateEnter = () => {
    const elems = document.querySelectorAll(`.waypoint-${elemKey} > *`);

    anime({
      targets: elems,
      translateY: 0,
      scale: 1,
      opacity: 1,
      easing: 'easeOutCubic',
      duration: d,
    });
  };

  const animateLeave = (e: any) => {
    const {currentPosition} = e;
    const elems = document.querySelectorAll(`.waypoint-${elemKey} > *`);
    anime({
      targets: elems,
      translateY:
        animation === 'slide'
          ? reverse
            ? currentPosition === 'above'
              ? y
              : -y
            : currentPosition === 'above'
            ? -y
            : y
          : 0,
      scale: animation === 'scale' ? 0.9 : 1,
      opacity: 0,
      easing: 'easeOutCubic',
      duration: d,
    });
  };

  return (
    <Waypoint onEnter={animateEnter} onLeave={animateLeave}>
      <div className={`waypoint-${elemKey}`} style={{height: '100%', maxWidth: '100%'}}>
        {children}
      </div>
    </Waypoint>
  );
}
