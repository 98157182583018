import React, {ReactElement, useContext, useState} from 'react';
import {useLocation} from 'react-router-dom';
import useSWR from 'swr';
import {AppContext} from '../../context/AppContext';
import {sanitizeUppercase} from '../../lib/utils';

import css from './Home.module.scss';

import Image from '../../components/lib/Image';
import AnimateIn from '../../components/lib/AnimateIn';
import AnimateImageIn from '../../components/lib/AnimateImageIn';
import ContactForm from './ContactForm';

interface Props {}

export default function Home(props: Props): ReactElement {
  const {locale, setPageMeta} = useContext(AppContext);
  const {pathname} = useLocation();
  const [topImageLoaded, setTopImageLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const pageRes = useSWR('/home-page');
  const page = pageRes.data?.items[0].data;

  const menuProductsRes = useSWR('/products?$orderby=data/order/iv');
  const menuProducts = menuProductsRes.data?.items;

  React.useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
  }, [isModalOpen]);

  React.useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'Escape') setIsModalOpen(false);
    };

    document.addEventListener('keyup', handleKeyUp);
  }, []);

  React.useEffect(() => {
    if (!page || pathname !== '/') return;
    setPageMeta({title: '', description: page.topSection[locale][0].teaser});
  }, [page, locale, setPageMeta, pathname]);

  return (
    <div className={css.Home}>
      {page && menuProducts && (
        <>
          <section className={css.Home_TopSection} id="welcome-section">
            {topImageLoaded && (
              <div className={css.stickyContainer}>
                <div className={css.metaContainer}>
                  <h1 className={css.title}>{page.topSection[locale][0].title}</h1>
                  <div
                    className={css.teaser}
                    dangerouslySetInnerHTML={{__html: page.topSection[locale][0].teaser}}
                  ></div>
                </div>
              </div>
            )}

            <div className={css.imageContainer}>
              <Image
                alt={page.topSection[locale][0].title}
                src={page.topSection[locale][0].image[0]}
                srcSet={true}
                onLoad={() => setTopImageLoaded(true)}
                loaderSize="large"
                loaderColor="#072944"
              />
            </div>
          </section>

          <section className={css.Home_StorySection} id="story-section">
            <AnimateIn duration={2000}>
              <h2 className={css.title}>{page.storySection[locale][0].title}</h2>
              <div className={css.description}>
                <div dangerouslySetInnerHTML={{__html: page.storySection[locale][0].description1}}></div>
              </div>
            </AnimateIn>

            <div className={css.imageContainer}>
              <AnimateImageIn axis="vertical">
                <Image
                  alt={page.storySection[locale][0].title}
                  src={page.storySection[locale][0].image[0]}
                  srcSet={true}
                  loaderSize="medium"
                  loaderColor="#072944"
                />
              </AnimateImageIn>
            </div>

            <AnimateIn duration={2000}>
              <div className={`${css.description} ${css.__bottom}`}>
                <div dangerouslySetInnerHTML={{__html: page.storySection[locale][0].description2}}></div>
              </div>
            </AnimateIn>
          </section>

          <section className={css.Home_MenuSection} id="menu-section">
            <div className={css.Menu_main}>
              {menuProducts.map((item: any, i: number) => (
                <div key={item.id} className={css.MenuItem}>
                  <div className={css.imageContainer}>
                    <AnimateImageIn axis="horizontal" reverse={i % 2 === 0}>
                      <Image
                        alt={item.data.title[locale]}
                        src={item.data.image[0]}
                        width="500"
                        loaderSize="medium"
                        loaderColor="#072944"
                      />
                    </AnimateImageIn>
                  </div>

                  <div className={css.metaContainer}>
                    <AnimateIn animation="slide" duration={1000} offsetY={60}>
                      <div className={css.number}>{`0${i + 1}`}</div>
                      <h3 className={css.title}>{item.data.title[locale]}</h3>
                      <h4 className={css.subtitle}>{item.data.subtitle[locale]}</h4>
                      <div
                        className={css.description}
                        dangerouslySetInnerHTML={{__html: item.data.description[locale]}}
                      ></div>
                    </AnimateIn>
                  </div>
                </div>
              ))}
            </div>

            <AnimateIn duration={1000} animation="slide">
              <div className={css.Menu_sides}>
                <h2 className={css.title}>{page.menuSection[locale][0].sidesTitle}</h2>
                <h3 className={css.subtitle}>{page.menuSection[locale][0].sidesSubtitle}</h3>
                <div
                  className={css.description}
                  dangerouslySetInnerHTML={{__html: page.menuSection[locale][0].sidesDescription}}
                ></div>

                <a
                  className={css.primaryButton}
                  href="https://coolchicks.gr/static/CoolChicks-Delivery-Menu-Web.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {page.menuSection[locale][0].viewMenuAction}
                </a>
              </div>
            </AnimateIn>

            <div className={css.Menu_image}>
              <Image
                alt={page.menuSection[locale][0].title}
                src={page.menuSection[locale][0].image[0]}
                srcSet={true}
                loaderSize="medium"
                loaderColor="#072944"
              />
            </div>
          </section>

          <section className={css.Home_ContactSection} id="contact-section">
            <div className={css.ContactSection_formModal} data-open={isModalOpen}>
              <button type="button" className={css.closeButton} onClick={() => setIsModalOpen(false)}>
                <svg viewBox="0 0 24 24">
                  <g stroke="currentColor" strokeWidth="1">
                    <line x1="0.0866148148" y1="21.9807704" x2="21.9798741" y2="0.0866962963"></line>
                    <line x1="21.980037" y1="21.9807704" x2="0.0867777778" y2="0.0866962963"></line>
                  </g>
                </svg>
                <span>ESC</span>
              </button>

              <div className={css.formModal_inner}>
                <ContactForm
                  successTitle={page.contactSection[locale][0].successMessageTitle}
                  successSubtitle={page.contactSection[locale][0].successMessageSubtitle}
                  contactFormTitle={page.contactSection[locale][0].contactFormTitle}
                  contactFormSubtitle={page.contactSection[locale][0].contactFormSubtitle}
                  shouldResetSuccess={!isModalOpen}
                />
              </div>
            </div>

            <div className={css.ContactSection_inner}>
              <h2 className={css.title}>{sanitizeUppercase(page.contactSection[locale][0].title)}</h2>
              <h3 className={css.subtitle}>{page.contactSection[locale][0].subtitle}</h3>
              <div
                className={css.teaser}
                dangerouslySetInnerHTML={{__html: page.contactSection[locale][0].teaser}}
              ></div>
              <button type="button" className={css.primaryButton} onClick={() => setIsModalOpen(true)}>
                {page.contactSection[locale][0].action}
              </button>
            </div>
          </section>

          <section className={css.Appstore} id="app-section">
            <div className={css.Appstore_inner}>
              <div className={css.imageContainer}>
                <Image
                  alt="Cool Chicks App screenshot"
                  src="/images/app-screenshot.png"
                  width="400"
                  loaderSize="medium"
                  loaderColor="white"
                />
              </div>
              <AnimateIn duration={1000} animation="slide">
                <div className={css.metaContainer}>
                  <h3 className={css.title}>{page.appFeature[locale][0].title}</h3>
                  <h4 className={css.subtitle}>{page.appFeature[locale][0].subtitle}</h4>
                  {page.appFeature[locale][0].note && <p className={css.note}>{page.appFeature[locale][0].note}</p>}
                  <div className={css.teaser}>{page.appFeature[locale][0].teaser}</div>
                  <div className={css.downloadLinks}>
                    {page.appFeature[locale][0].appleStoreUrl ? (
                      <a href={page.appFeature[locale][0].appleStoreUrl} target="_blank" rel="noreferrer">
                        <Image alt="Apple store" src="/images/apple-store.png" width="210" />
                      </a>
                    ) : (
                      <Image alt="Apple store" src="/images/apple-store.png" width="210" />
                    )}

                    {page.appFeature[locale][0].googleStoreUrl ? (
                      <a href={page.appFeature[locale][0].googleStoreUrl} target="_blank" rel="noreferrer">
                        <Image alt="Google store" src="/images/google-store.png" width="210" />
                      </a>
                    ) : (
                      <Image alt="Google store" src="/images/google-store.png" width="210" />
                    )}
                  </div>
                </div>
              </AnimateIn>
            </div>
          </section>

          <div className={css.paymentMethods}>
            <img alt="Payment methods" src="/images/payment-methods.png?v=2" />
          </div>
        </>
      )}
    </div>
  );
}
