import React, {ReactElement, useContext, useState} from 'react';
import axios from 'axios';
import {useForm} from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import {AppContext} from '../../context/AppContext';

import css from './Home.module.scss';

interface Props {
  contactFormTitle: string;
  contactFormSubtitle: string;
  successTitle: string;
  successSubtitle: string;
  shouldResetSuccess: boolean;
}

export default function ContactForm(props: Props): ReactElement {
  const {successTitle, successSubtitle, contactFormTitle, contactFormSubtitle, shouldResetSuccess} = props;
  const {getLabel} = useContext(AppContext);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: {errors, isSubmitting},
  } = useForm();

  React.useEffect(() => {
    if (shouldResetSuccess) {
      clearErrors();
      setIsFormSuccess(false);
    }
  }, [shouldResetSuccess, clearErrors]);

  const onSubmit = async (values: any) => {
    const data = {
      firstName: {
        iv: values.firstName,
      },
      lastName: {
        iv: values.lastName,
      },
      email: {
        iv: values.email,
      },
      phone: {
        iv: values.phone,
      },
      address: {
        iv: values.address,
      },
      message: {
        iv: values.message,
      },
    };

    await axios.post(`/contact-form`, data);

    setIsFormSuccess(true);
    reset();

    const elemToScroll = document.querySelector(`.${css.ContactSection_formModal} .${css.formModal_inner}`);
    elemToScroll?.scrollTo(0, 0);
  };

  return (
    <div className={css.ContactForm}>
      {isFormSuccess && (
        <div className={css.ContactForm_success}>
          <div className={css.successInner}>
            <div className={css.successText}>
              <h3>{successTitle} </h3>
              <p>{successSubtitle}</p>
            </div>
            <div className={css.brand}>
              <img alt="Cool Chicks brand" src="/brand/brand-with-tagline.svg" />
            </div>
          </div>
        </div>
      )}

      {!isFormSuccess && (
        <div>
          <div className={css.ContactForm_intro}>
            <h3>{contactFormTitle}</h3>
            <p>{contactFormSubtitle}</p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-control">
                <input type="text" id="firstName" {...register('firstName', {required: true})} />
                <label htmlFor="firstName">{getLabel(['forms', 'first-name'], true)} *</label>
                {errors.firstName && (
                  <p className="form-control-error">{getLabel(['forms-errors', errors.firstName.type])}</p>
                )}
              </div>

              <div className="form-control">
                <input type="text" id="lastName" {...register('lastName', {required: true})} />
                <label htmlFor="lastName">{getLabel(['forms', 'last-name'], true)} *</label>
                {errors.lastName && (
                  <p className="form-control-error">{getLabel(['forms-errors', errors.lastName.type])}</p>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-control">
                <input
                  type="email"
                  id="email"
                  {...register('email', {
                    required: true,
                    validate: {
                      email: (value: string) => isEmail(value),
                    },
                  })}
                />
                <label htmlFor="email">EMAIL *</label>
                {errors.email && <p className="form-control-error">{getLabel(['forms-errors', errors.email.type])}</p>}
              </div>

              <div className="form-control">
                <input type="tel" id="phone" {...register('phone')} placeholder="6900000000" />
                <label htmlFor="phone">{getLabel(['forms', 'phone'], true)}</label>
              </div>
            </div>

            <div className="form-control">
              <input
                type="text"
                id="address"
                placeholder={getLabel(['forms', 'address-placeholder'])}
                {...register('address')}
              />
              <label htmlFor="address">{getLabel(['forms', 'address'], true)}</label>
            </div>

            <div className="form-control">
              <textarea
                id="message"
                placeholder={getLabel(['forms', 'type-your-message'])}
                {...register('message', {required: true})}
              />
              <label htmlFor="message">{getLabel(['forms', 'message'], true)} *</label>
              {errors.message && (
                <p className="form-control-error">{getLabel(['forms-errors', errors.message.type])}</p>
              )}
            </div>

            <div className="form-action">
              <button className={css.primaryButton} disabled={isSubmitting}>
                {getLabel(['forms', 'send'])}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
