import React from 'react';
import axios from 'axios';

const isProduction = process.env.NODE_ENV === 'production';

const storedVersion = localStorage.getItem('version');
let currentVersion: string;

type Props = {
  children: React.ReactElement;
};

export default function CacheBuster(props: Props) {
  React.useEffect(() => {
    if (isProduction) {
      fetchMeta();
      window.addEventListener('focus', fetchMeta);
    }
  }, []);

  async function fetchMeta() {
    const timestamp = +new Date();
    const res = await axios.get(`/version.json?v=${timestamp}`, {baseURL: ''});
    currentVersion = res.data;

    if (currentVersion !== storedVersion) {
      if (storedVersion) {
        clearCacheAndReload();
      } else {
        localStorage.setItem('version', currentVersion);
      }
    }
  }

  function clearCacheAndReload() {
    localStorage.clear();
    localStorage.setItem('version', currentVersion);

    if (caches) {
      caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.reload();
  }

  return props.children;
}
