import React from 'react';
import Bowser from 'bowser';

const _W: any = window;

const browser: any = Bowser.getParser(_W.navigator.userAgent);
const isAndroid = browser.parsedResult.os.name === 'Android';
const isIos = browser.parsedResult.os.name === 'iOS';

export default function DownloadApp() {
  console.log('download app');
  let downloadUrl;
  if (isAndroid) downloadUrl = 'https://play.google.com/store/apps/details?id=com.coolchicks.ordering';
  if (isIos) downloadUrl = 'https://apps.apple.com/gr/app/cool-chicks/id1601485117';
  if (downloadUrl) _W.location = downloadUrl;
 

  return <div></div>;
}
