import React, {ReactElement, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import useSWR from 'swr';
import {AppContext} from '../../context/AppContext';

import Loader from '../../components/lib/Loader';

import css from './Page.module.scss';

interface Props {}

export default function Page(props: Props): ReactElement {
  const {locale, getLabel, setPageMeta} = useContext(AppContext);
  const params: any = useParams();
  const pageRes = useSWR(`/generic-pages?$filter=data/slug/iv eq '${params.slug}'`);
  const page = pageRes.data?.items[0].data;

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  React.useEffect(() => {
    if (!page) return;
    setPageMeta({title: page.title[locale]});
  }, [page, locale, setPageMeta]);

  return (
    <div className={css.Page}>
      {!pageRes.data && !pageRes.error && <Loader position="fixed" size="large" color="#d6902b" />}
      {page && (
        <>
          <div className={css.Page_top}>
            <Link className={css.backButton} to="/">
              <svg>
                <polyline fill="none" stroke="currentColor" strokeWidth="2" points="15 1 8.001 7.583 1 1"></polyline>
              </svg>
              <span>{getLabel(['back'])}</span>
            </Link>
          </div>

          <div className={css.Page_main}>
            <h1 className={css.title}>{page.title[locale]}</h1>
            <div className={css.description} dangerouslySetInnerHTML={{__html: page.description[locale]}}></div>
          </div>
        </>
      )}
    </div>
  );
}
