import React from 'react';

import css from './Loader.module.scss';

interface Props {
  position?: 'absolute' | 'fixed';
  size?: 'small' | 'medium' | 'large';
  color?: string;
}

export default function Loader(props: Props) {
  const {size, position, color} = props;

  return (
    <div className={`Loader ${css.Loader}`} data-position={position || 'absolute'} data-size={size || 'medium'}>
      <div className={css.LoaderInner} style={{color: color || 'inherit'}}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
