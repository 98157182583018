import React, {ReactElement} from 'react';

import css from './Header.module.scss';

interface Props {}

export default function Header(props: Props): ReactElement {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [zeroToOne, setZeroToOne] = React.useState(0);

  const headerRef = React.createRef<HTMLElement>();

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  const onScroll = () => {
    const scrollStart = 100;
    const scrollLength = 300;
    const wY = window.scrollY;
    const step = (1 / scrollLength) * (wY - scrollStart);
    const zeroToOne = step <= 0 ? 0 : step <= 1 ? step : 1;
    setZeroToOne(zeroToOne);
    setIsCollapsed(zeroToOne > 0.5);
  };

  const onNavButtonClick = (id: string) => {
    const elem = document.getElementById(id);
    if (elem) {
      const rect = elem.getBoundingClientRect();
      const offsetY = rect.top + window.scrollY;
      window.scrollTo({
        top: id === 'welcome-section' ? offsetY : offsetY - 100,
        behavior: 'smooth',
      });
    }
  };

  return (
    <header
      className={css.Header}
      ref={headerRef}
      data-collapsed={isCollapsed}
      style={{backgroundColor: `rgba(255,255,255,${zeroToOne < 0.95 ? zeroToOne : 0.95})`}}
    >
      <div className={css.brandLogo}>
        <button onClick={() => onNavButtonClick('welcome-section')}>
          <svg viewBox="0 0 101 54">
            <g id="Group-9" fill="currentColor">
              <path d="M42.4794,11.031 C43.0744,11.031 43.5564,10.549 43.5564,9.954 C43.5564,9.359 43.0744,8.877 42.4794,8.877 C41.8844,8.877 41.4024,9.359 41.4024,9.954 C41.4024,10.549 41.8844,11.031 42.4794,11.031"></path>
              <polygon id="Fill-2" points="45.5966 8.09 45.5966 11.964 50.265 10.027"></polygon>
              <path d="M35.0498,9.469 L24.1058,29.7069 L28.0918,31.7049 C27.7278,32.6219 27.0588,33.4259 26.1278,33.9429 C23.9858,35.1329 21.2838,34.3609 20.0938,32.2189 C19.6398,31.4019 19.4718,30.5039 19.5558,29.6379 L14.6648,29.6379 C14.5868,31.3089 14.9558,33.0199 15.8278,34.5889 C18.3268,39.0879 23.9998,40.7079 28.4978,38.2089 C30.3208,37.1959 31.6678,35.6609 32.4628,33.8949 L34.4508,34.8919 C33.4628,37.0439 31.8068,38.9129 29.5778,40.1519 C24.0068,43.2469 16.9808,41.2399 13.8848,35.6689 C12.8218,33.7579 12.3618,31.6749 12.4388,29.6379 L7.5808,29.6379 C7.5048,32.4749 8.1618,35.3669 9.6408,38.0269 C14.0378,45.9429 24.0208,48.7939 31.9368,44.3959 C35.0528,42.6639 37.3838,40.0659 38.7958,37.0699 L40.7188,38.0329 C39.1238,41.4209 36.4898,44.3579 32.9678,46.3149 C24.0278,51.2829 12.7528,48.0629 7.7848,39.1219 C6.1258,36.1359 5.3808,32.8879 5.4568,29.7029 L0.5548,29.7029 C0.4788,33.6949 1.4218,37.7609 3.5008,41.5029 C9.7828,52.8089 24.0418,56.8819 35.3478,50.5989 C39.7668,48.1439 43.0788,44.4689 45.1018,40.2299 L45.1028,40.2299 C46.5738,37.1639 47.4268,33.7419 47.4258009,30.1129 C47.4208,21.177 42.4108,13.413 35.0498,9.469"></path>
              <path
                d="M44.3593,0.776 C42.0163,-0.704 38.9183,-0.005 37.4383,2.337 C37.4353,2.341 37.4333,2.346 37.4313,2.35 L37.4303,2.349 C35.0883,0.87 31.9903,1.569 30.5093,3.911 L38.9923,9.27 C38.9953,9.266 38.9973,9.262 38.9993,9.257 L39.0003,9.258 L44.3593,0.776 Z"
                fill={isCollapsed ? 'var(--red)' : ''}
              ></path>
              <path d="M58.735,11.031 C58.14,11.031 57.658,10.549 57.658,9.954 C57.658,9.359 58.14,8.877 58.735,8.877 C59.33,8.877 59.812,9.359 59.812,9.954 C59.812,10.549 59.33,11.031 58.735,11.031"></path>
              <polygon id="Fill-6" points="55.618 8.09 55.618 11.964 50.95 10.027"></polygon>
              <path d="M66.165,9.469 L77.109,29.7069 L73.123,31.7049 C73.487,32.6219 74.157,33.4259 75.087,33.9429 C77.229,35.1329 79.931,34.3609 81.121,32.2189 C81.575,31.4019 81.743,30.5039 81.659,29.6379 L86.55,29.6379 C86.628,31.3089 86.259,33.0199 85.388,34.5889 C82.888,39.0879 77.215,40.7079 72.717,38.2089 C70.894,37.1959 69.547,35.6609 68.752,33.8949 L66.764,34.8919 C67.752,37.0439 69.408,38.9129 71.637,40.1519 C77.209,43.2469 84.234,41.2399 87.33,35.6689 C88.393,33.7579 88.853,31.6749 88.776,29.6379 L93.635,29.6379 C93.711,32.4749 93.053,35.3669 91.574,38.0269 C87.177,45.9429 77.194,48.7939 69.279,44.3959 C66.162,42.6639 63.831,40.0659 62.419,37.0699 L60.496,38.0329 C62.092,41.4209 64.725,44.3579 68.248,46.3149 C77.187,51.2829 88.462,48.0629 93.43,39.1219 C95.09,36.1359 95.834,32.8879 95.758,29.7029 L100.66,29.7029 C100.737,33.6949 99.793,37.7609 97.714,41.5029 C91.432,52.8089 77.174,56.8819 65.867,50.5989 C61.448,48.1439 58.136,44.4689 56.113,40.2299 L56.112,40.2299 C54.641,37.1639 53.788,33.7419 53.7889991,30.1129 C53.794,21.177 58.804,13.413 66.165,9.469"></path>
              <path
                d="M56.855,0.776 C59.198,-0.704 62.296,-0.005 63.776,2.337 C63.779,2.341 63.781,2.346 63.783,2.35 L63.784,2.349 C66.126,0.87 69.225,1.569 70.705,3.911 L62.222,9.27 C62.219,9.266 62.217,9.262 62.215,9.257 L62.214,9.258 L56.855,0.776 Z"
                fill={isCollapsed ? 'var(--red)' : ''}
              ></path>
            </g>
          </svg>
        </button>
      </div>
      <div className={css.brandName}>Cool Chicks</div>
      <div className={css.brandCity}>ATHENS</div>
      <div className={css.Nav}>
        <div className={css.Nav_main}>
          <button onClick={() => onNavButtonClick('story-section')}>OUR STORY</button>
          <button onClick={() => onNavButtonClick('menu-section')}>MENU</button>
          <button onClick={() => onNavButtonClick('contact-section')}>CONTACT</button>
          <button onClick={() => onNavButtonClick('app-section')}>ORDER NOW</button>
        </div>
        <div className={css.Nav_lang}>
          <a href="/" title="ΕΛΛΗΝΙΚΑ">
            EΛ
          </a>
          <span>|</span>
          <a href="/en" title="ENGLISH">
            EN
          </a>
        </div>
      </div>
    </header>
  );
}
