import React, {ReactElement, useState} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {SWRConfig} from 'swr';
import axios from 'axios';
import {AppContextProvider, AppContext} from './context/AppContext';
import {apiUrl, squidexClient, defaultLocale} from './config/app.json';

import './lib/polyfills';
import './styles/global.scss';
import css from './App.module.scss';

import CacheBuster from './components/lib/CacheBuster';
import Head from './components/lib/Head';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home/Home';
import Page from './pages/Page/Page';
import DownloadApp from './pages/DownloadApp/DownloadApp';

interface Props {}

export default function App(props: Props): ReactElement {
  const [hasToken, setHasToken] = useState(false);

  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers['X-Flatten'] = true;

  React.useEffect(() => {
    getToken();
  }, []);

  function getToken() {
    const url = `https://cms.greymatter.gr/identity-server/connect/token`;
    const data = `grant_type=client_credentials&client_id=${squidexClient.id}&client_secret=${squidexClient.secret}&scope=squidex-api`;

    axios.post(url, data).then((res) => {
      axios.defaults.headers.Authorization = `Bearer ${res.data.access_token}`;
      setHasToken(true);
    });
  }

  return (
    <CacheBuster>
      <AppContextProvider>
        <AppContext.Consumer>
          {(ctx) => (
            <div id="App">
              {ctx.locale && (
                <Router basename={ctx.locale === defaultLocale ? '/' : `${ctx.locale}/`}>
                  {hasToken && (
                    <SWRConfig
                      value={{
                        fetcher: (url) => axios.get(url).then((res) => res.data),
                        revalidateOnFocus: false,
                        revalidateOnReconnect: false,
                      }}
                    >
                      <Head />

                      <div className={css.Layout}>
                        <Header />

                        <main className={css.Main}>
                          <Route component={Home} />
                          <Route path="/app/download" component={DownloadApp} />
                          <Route path="/page/:slug" component={Page} />
                        </main>

                        <Footer />
                      </div>
                    </SWRConfig>
                  )}
                </Router>
              )}
            </div>
          )}
        </AppContext.Consumer>
      </AppContextProvider>
    </CacheBuster>
  );
}
