import React, {ReactElement} from 'react';
import {Waypoint} from 'react-waypoint';
import anime from 'animejs';

interface Props {
  axis: 'horizontal' | 'vertical';
  reverse?: boolean;
  duration?: number;
  children: object;
}

export default function AnimateImageIn(props: Props): ReactElement {
  const {axis, reverse, duration, children} = props;

  const createRandomString = () => [...Array(10)].map((i) => (~~(Math.random() * 36)).toString(36)).join('');
  const elemKey = createRandomString();
  const initialOffset = reverse ? '30%' : '-30%';
  const defaultDuration = 1000;

  React.useEffect(() => {
    const elems = document.querySelectorAll(`.waypoint-${elemKey} > *`);
    anime.set(elems, {
      translateX: axis === 'horizontal' ? initialOffset : 0,
      translateY: axis === 'vertical' ? initialOffset : 0,
      opacity: 0,
    });
  }, [elemKey, axis, initialOffset]);

  const animateEnter = (e: any) => {
    const elems = document.querySelectorAll(`.waypoint-${elemKey} > *`);

    anime({
      targets: elems,
      translateX: 0,
      translateY: 0,
      opacity: 1,
      easing: 'easeOutCubic',
      duration: duration || defaultDuration,
    });
  };

  const animateLeave = (e: any) => {
    const elems = document.querySelectorAll(`.waypoint-${elemKey} > *`);
    anime({
      targets: elems,
      translateX: axis === 'horizontal' ? initialOffset : 0,
      translateY: axis === 'vertical' ? initialOffset : 0,
      opacity: 0,
      easing: 'easeOutCubic',
      duration: duration || defaultDuration,
    });
  };

  return (
    <Waypoint onEnter={animateEnter} onLeave={animateLeave}>
      <div className={`waypoint-${elemKey}`} style={{height: '100%', maxWidth: '100%'}}>
        {children}
      </div>
    </Waypoint>
  );
}
