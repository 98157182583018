import React, {ReactElement, useState} from 'react';
import {Waypoint} from 'react-waypoint';
import {assetsUrl} from '../../config/app.json';

import Loader from './Loader';

const cacheDurationSeconds = 3600 * 24 * 30;

interface Props {
  alt: string;
  src: string;
  srcSet?: boolean;
  sizes?: string;
  width?: string;
  objectFit?: 'cover' | 'contain';
  loaderSize?: 'small' | 'medium' | 'large';
  loaderColor?: string;
  onLoad?: () => void;
  onError?: () => void;
}

export default function Image(props: Props): ReactElement {
  const {alt, src, srcSet, sizes, width, objectFit, loaderSize, loaderColor, onLoad, onError} = props;
  const [isLoading, setIsLoading] = useState(false);

  let imageSrc = `${assetsUrl}/${src}?cache=${cacheDurationSeconds}`;

  if (src.startsWith('/images')) {
    imageSrc = `${src}?cache=${cacheDurationSeconds}`;
  }

  const imgRef = React.createRef<HTMLImageElement>();

  const constructImageSrcSet = () => {
    const sizes = [640, 768, 900, 1080, 1280, 1920];
    let srcSet = '';
    sizes.map((size) => (srcSet += `${imageSrc}&width=${size} ${size}w,`));
    return srcSet;
  };

  const onEnter = () => {
    const elem = imgRef.current;
    if (!elem) return;
    // elem.style.opacity = '0';
    elem.style.transition = '300ms ease-out';
    elem.src = width ? `${imageSrc}&width=${width}` : `${imageSrc}`;

    if (srcSet) {
      elem.srcset = constructImageSrcSet();
      elem.sizes = sizes || '100vw';
    }

    setIsLoading(true);
  };

  const onImageLoad = () => {
    const elem = imgRef.current;
    if (!elem) return;
    // elem.style.opacity = '1';
    setIsLoading(false);
    if (onLoad) onLoad();
  };

  const onImageError = () => {
    setIsLoading(false);
    if (onError) onError();
  };

  return (
    <Waypoint onEnter={onEnter} topOffset={'-5%'} bottomOffset={'-5%'}>
      <div className="Image" style={{position: 'relative', height: '100%'}}>
        {isLoading && loaderSize && <Loader size={loaderSize} color={loaderColor} />}

        <img
          ref={imgRef}
          alt={alt}
          onLoad={onImageLoad}
          onError={onImageError}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            objectFit: objectFit || 'cover',
            objectPosition: 'center',
          }}
        />
      </div>
    </Waypoint>
  );
}
